import * as React from "react";
import { Link } from "gatsby";
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

// maybe get latest release from the yaml?
const LINUX_ASPHR_VERSION = "0.1.16";
const MAC_ASPHR_VERSION = "0.1.12";
const X86_LINK = `https://github.com/anysphere/client/releases/download/v${MAC_ASPHR_VERSION}/Anysphere-${MAC_ASPHR_VERSION}.dmg`;
const M1_LINK = `https://github.com/anysphere/client/releases/download/v${MAC_ASPHR_VERSION}/Anysphere-${MAC_ASPHR_VERSION}-arm64.dmg`;
const LINUX_LINK = `https://github.com/anysphere/client/releases/download/v${LINUX_ASPHR_VERSION}/Anysphere-${LINUX_ASPHR_VERSION}.AppImage`;

const DownloadPage = () => {
  const [tos, setTos] = React.useState(true);

  return (
    <main
      style={{
        fontFamily:
          '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
      }}
      className="dark:bg-gray-900 bg-asbeige h-screen"
    >
      <NavBar bigLogo={false} />
      <Helmet>
        <title>Download – Anysphere</title>
      </Helmet>
      <div className="w-full grid justify-items-center gap-10 pt-20 px-6 pb-20">
        <div className="grid grid-cols-1 gap-10 lg:gap-20">
          <div className="grid grid-cols-1 gap-4 max-w-5xl border px-6 py-6 rounded-lg hover:shadow-md shadow-sm bg-white">
            <div className="font-bold text-asbrown-dark text-xl mx-auto text-center">
              Download Anysphere
            </div>
            <p className="text-asbrown-dark mx-auto text-center">
              You're one step away from complete privacy.
            </p>
            <p className="text-asbrown-dark">
              <b>
                Please remember that this is an alpha product. While we think
                that our current implementation meets our privacy guarantees, we
                may have bugs, and those bugs may have privacy consequences.
              </b>{" "}
              While in alpha, don't send anything on Anysphere that you wouldn't
              send on Signal.
            </p>
            {/* <div className="mx-auto">
              <input
                type="checkbox"
                name="tos"
                id="tos"
                checked={tos}
                onClick={() => setTos((t) => !t)}
              ></input>
              <label htmlFor="tos" className="ml-2">
                I have read and agree to the{" "}
                <Link to="terms-of-service" className="underline">
                  Anysphere Terms of Service
                </Link>
                .
              </label>
            </div> */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pt-4">
              <a
                href={tos ? M1_LINK : "#"}
                className={`text-white transform ease-in duration-100 hover:shadow-md md:mx-0 mx-auto  text-primary shadow-sm font-semibold rounded-xl flex items-center justify-center py-3 border border-transparent text-base  black md:py-4 md:text-lg px-8 bg-asgreen-light ${
                  tos ? "" : "opacity-50"
                }`}
              >
                <div className="flex flex-row gap-4">
                  <span>⤓</span>
                  <span>Apple Silicon (M1)</span>
                </div>
              </a>
              <a
                href={tos ? X86_LINK : "#"}
                className={`text-white transform ease-in duration-100 hover:shadow-md md:mx-0 mx-auto  text-primary shadow-sm font-semibold rounded-xl flex items-center justify-center py-3 border border-transparent text-base  black md:py-4 md:text-lg px-8 bg-asgreen-light ${
                  tos ? "" : "opacity-50"
                }`}
              >
                <div className="flex flex-row gap-4">
                  <span>⤓</span>
                  <span>Intel Mac (x86)</span>
                </div>
              </a>
              <a
                href={tos ? LINUX_LINK : "#"}
                className={`text-white transform ease-in duration-100 hover:shadow-md md:mx-0 mx-auto  text-primary shadow-sm font-semibold rounded-xl flex items-center justify-center py-3 border border-transparent text-base  black md:py-4 md:text-lg px-8 bg-asgreen-light ${
                  tos ? "" : "opacity-50"
                }`}
              >
                <div className="flex flex-row gap-4">
                  <span>⤓</span>
                  <span>Linux AppImage (x86)</span>
                </div>
              </a>
            </div>
            <p className="text-center text-asbrown-300 pb-4">
              (or build{" "}
              <a
                className="underline"
                href="https://github.com/anysphere/client"
              >
                from source
              </a>
              )
            </p>
            <p className="text-asbrown-light mx-auto text-center">
              Only Mac and Linux supported for now. Windows version is coming
              soon!
            </p>
            <p className="text-asbrown-light mx-auto text-center">
              <i>Please only share this page with other alpha testers.</i>
            </p>
          </div>
        </div>
        <div className="text-asbrown-light pt-20 pb-10">
          Questions? Join{" "}
          <a className="hover:underline" href="https://discord.gg/FxCBpJRbT9">
            our Discord
          </a>
          , or contact us at{" "}
          <a className="hover:underline" href="mailto:hello@anysphere.co">
            hello@anysphere.co
          </a>
          .
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default DownloadPage;
