import * as React from "react";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <>
      <footer className="w-full py-10 px-2 text-asbrown-dark mt-auto pt-24">
        <hr className="border-asbrown-100 mb-10"></hr>
        <div className="grid grid-cols-2 sm:grid-cols-4 items-top sm:justify-items-center gap-y-6 mx-4">
          <div className="grid grid-cols-1 content-start gap-2">
            <h3 className="font-bold text-lg">Anysphere</h3>
            <h6>© Copyright 2022</h6>
          </div>
          <div className="grid grid-cols-1 content-start gap-2">
            <h3 className="font-bold text-lg">Site</h3>
            <Link to="/" className="hover:underline">
              Home
            </Link>
            <Link to="/team" className="hover:underline">
              Team
            </Link>
            <Link to="/blog" className="hover:underline">
              Blog
            </Link>
          </div>
          <div className="grid grid-cols-1 content-start gap-2">
            <h3 className="font-bold text-lg">Legal</h3>
            <Link to="/privacy-policy" className="hover:underline">
              Privacy Policy
            </Link>
            <Link to="/terms-of-service" className="hover:underline">
              Terms of Service
            </Link>
          </div>
          <div className="grid grid-cols-1 content-start gap-2">
            <h3 className="font-bold text-lg">Connect</h3>
            <a href="https://twitter.com/anysphere" className="hover:underline">
              Twitter
            </a>
            <a className="hover:underline" href="mailto:hello@anysphere.co">
              hello@anysphere.co
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
